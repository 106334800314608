







import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import HomeAdmin from '../views/HomeAdmin.vue';
import HomeUser from '../views/HomeUser.vue';

import { User } from '../api/users/user.class';
import { Role } from '../api/auth/role.enum';

const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { HomeAdmin, HomeUser } })
export default class Home extends Vue {
  get isUser() {
    return this.currentUser.role === Role.User;
  }

  @usersModule.Getter('loggedInUser')
  currentUser!: User;
}

































import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop, VModel } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class StateCheckboxGroup extends Vue {
  id: number;

  @Prop()
  states!: string[];

  @VModel({
    type: Array,
  })
  selectedStates!: string[];

  get buttonId() {
    return `stateButton-${this.id}`;
  }

  created() {
    this.id = Math.floor(Math.random() * 1000);
  }
}

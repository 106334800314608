<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    values: {
      type: Array,
    },
    showGross: {
      type: Boolean,
      default: true,
    },
    showNet: {
      type: Boolean,
      default: false,
    },
    showRefunds: {
      type: Boolean,
      default: false,
    },
    showChargebacks: {
      type: Boolean,
      default: false,
    },
    prependLabel: String,
  },
  data() {
    return {
      datasets: [],
      options: {
        legend: {
          display: false,
        },
        showTooltips: true,

        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                drawBorder: false,
              },
              ticks: {
                callback: this.yLabelCallback,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    showGross() {
      this.recalculateDatasets();
      this.renderChart(this.data, this.options);
    },
    showNet() {
      this.recalculateDatasets();
      this.renderChart(this.data, this.options);
    },
    showRefunds() {
      this.recalculateDatasets();
      this.renderChart(this.data, this.options);
    },
    showChargebacks() {
      this.recalculateDatasets();
      this.renderChart(this.data, this.options);
    },
  },
  computed: {
    data: function() {
      return {
        labels: this.values.map(x => x._id),
        datasets: this.datasets,
      };
    },
  },
  methods: {
    recalculateDatasets() {
      this.datasets = [];
      if (this.showGross) {
        this.datasets.push({
          label: 'Gross amount',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: '#4000ff',
          data: this.values.map(x => x.grossAmount.toFixed(2)),
        });
      }
      if (this.showNet) {
        this.datasets.push({
          label: 'Net amount',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: '#d14389',
          data: this.values.map(x => x.netAmount.toFixed(2)),
        });
      }
      if (this.showRefunds) {
        this.datasets.push({
          label: 'Refunds',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: '#1991eb',
          data: this.values.map(x => x.amountRefunded.toFixed(2)),
        });
      }
      if (this.showChargebacks) {
        this.datasets.push({
          label: 'Chargebacks',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: '#0c0033',
          data: this.values.map(x => x.amountChargedBack.toFixed(2)),
        });
      }
    },
    yLabelCallback(value, index, values) {
      if (this.prependLabel) {
        return `${this.prependLabel} ${value}`;
      }
      return value;
    },
  },
  mounted() {
    this.recalculateDatasets();
    this.renderChart(this.data, this.options);
  },
};
</script>

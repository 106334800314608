

































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { namespace } from 'vuex-class';

import BaseHeader from '../components/BaseHeader.vue';
import Checklist from '../components/Checklist.vue';
import Statcard from '../components/Statcard.vue';
import ProjectSelect from '../components/ProjectSelect.vue';
import AssetSelect from '../components/AssetSelect.vue';
import CompanySelect from '../components/CompanySelect.vue';
import PeriodicActivityChart from '../components/PeriodicActivityChart.vue';
import PeriodicPaymentsChart from '../components/PeriodicPaymentsChart.vue';
import PieChart from '../components/PieChart.vue';
import DateRangeSelect, { DateRange } from '../components/DateRangeSelect.vue';
import StateCheckboxGroup from '../components/StateCheckboxGroup.vue';

import { User } from '../api/users/user.class';
import { ChecklistItem } from '../shared/types/checklist-item.model';
import { CastType, Filter, Operator } from '../shared/types/filter.class';
import { Booking } from '../api/bookings/booking.model';
import { Asset } from '../api/assets/asset.model';
import { UserState } from '../api/users/user-state.enum';
import { Project } from '../api/projects/project.model';
import { Role } from '../api/auth/role.enum';
import { BookingState } from '../api/bookings/booking-state.enum';
import BookingsService from '../api/bookings/bookings.service';
import { Watch } from 'vue-property-decorator';
import { Company } from '@/api/companies/company.model';
import { PeriodicStatistic } from '@/shared/types/periodic-statistic.model';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { PaymentStatusStatistic } from '@/api/payments/payment-status-statistic.model';
import { BookingStatusStatistic } from '@/api/bookings/booking-status-statistic.model';

const usersModule = namespace('users');
const projectsModule = namespace('projects');
const assetsModule = namespace('assets');
const bookingsModule = namespace('bookings');
const companiesModule = namespace('companies');
const paymentsModule = namespace('payments');
const appModule = namespace('app');

const bookingsService = new BookingsService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    Checklist,
    Statcard,
    PeriodicActivityChart,
    PeriodicPaymentsChart,
    PieChart,
    DateRangeSelect,
    BaseHeader,
    AssetSelect,
    CompanySelect,
    ProjectSelect,
    StateCheckboxGroup,
  },
})
export default class Home extends Vue {
  showFilters = false;
  dateRange = new DateRange();
  numberOfActiveUsers = 0;
  numberOfUsers = 0;
  numberOfAssets = 0;
  numberOfBookings = 0;
  numberOfBookingsCurrentMonth = 0;
  numberOfProjects = 0;
  selectedWindow = 'month';
  selectedTimeframe = 'last-year';
  selectedCompanies: Company[] = [];
  selectedProjects: Project[] = [];
  selectedAssets: Asset[] = [];
  tempSelectedCompanies: Company[] = [];
  tempSelectedProjects: Project[] = [];
  tempSelectedAssets: Asset[] = [];
  filterToday = false;
  selectedBookingStates = [
    BookingState.Pending,
    BookingState.Started,
    BookingState.Ended,
    BookingState.Expired,
  ];
  selectedPaymentStates = ['Open', 'Paid', 'Pending', 'Failed'];
  showGross = true;
  showNet = false;
  showRefunds = false;
  showChargebacks = false;

  windows = [
    {
      value: 'month',
      text: 'Month',
    },
    {
      value: 'week',
      text: 'Week',
    },
    {
      value: 'day',
      text: 'Day',
    },
  ];

  introCompleted = true;
  checklistItems: ChecklistItem[] = [
    new ChecklistItem(
      'Setup your first <a target="_blank" href="/projects?openCreate=true">project</a>',
      false,
      true,
      true,
    ),
    new ChecklistItem(
      'Create your first <a target="_blank" href="/assets/new">asset</a>',
      false,
      true,
      true,
    ),
    new ChecklistItem(
      'Invite <a target="_blank" href="/users?openCreate=true">users</a>!',
      false,
      true,
      true,
    ),
    new ChecklistItem(
      'Wait for the first booking &#128526;',
      false,
      true,
      true,
    ),
  ];

  @usersModule.Getter('loggedInUser')
  currentUser!: User;

  @companiesModule.Getter('all')
  companies!: Company[];

  @usersModule.Getter('pagination')
  userPagination!: PaginateResult<User>;

  @assetsModule.Getter('pagination')
  assetPagination!: PaginateResult<Asset>;

  @assetsModule.Getter('all')
  assets!: Asset[];

  @bookingsModule.Getter('pagination')
  bookingPagination!: PaginateResult<Booking>;

  @bookingsModule.Getter('statistics')
  bookingStatistics!: PeriodicStatistic[];

  @bookingsModule.Getter('statusStatistics')
  bookingStatusStatistics!: BookingStatusStatistic[];

  @paymentsModule.Getter('statistics')
  paymentStatistics!: PeriodicStatistic[];

  @paymentsModule.Getter('statusStatistics')
  paymentStatusStatistics!: PaymentStatusStatistic[];

  @projectsModule.Getter('pagination')
  projectPagination!: PaginateResult<Project>;

  @projectsModule.Getter('all')
  projects!: Project[];

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @usersModule.Action('fetchAll')
  fetchUsers!: (filter?: Filter) => Promise<User[]>;

  @assetsModule.Action('fetchAll')
  fetchAssets!: (filter?: Filter) => Promise<Asset[]>;

  @bookingsModule.Action('fetchAll')
  fetchBookings!: (filter?: Filter) => Promise<Booking[]>;

  @bookingsModule.Action('fetchStatistics')
  fetchBookingStatistics!: (filter?: Filter) => Promise<PeriodicStatistic[]>;

  @bookingsModule.Action('fetchStatusStatistics')
  fetchBookingStatusStatistics!: (
    filter?: Filter,
  ) => Promise<BookingStatusStatistic[]>;

  @paymentsModule.Action('fetchStatistics')
  fetchPaymentStatistics!: (filter?: Filter) => Promise<PeriodicStatistic[]>;

  @paymentsModule.Action('fetchStatusStatistics')
  fetchPaymentStatusStatistics!: (
    filter?: Filter,
  ) => Promise<PaymentStatusStatistic[]>;

  @projectsModule.Action('fetchAll')
  fetchProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchCompanies!: (filter?: Filter) => Promise<Company[]>;

  get bookingStates() {
    return Object.keys(BookingState);
  }

  get paymentStates() {
    return ['Open', 'Paid', 'Pending', 'Failed'];
  }

  get isAdmin() {
    return (
      this.currentUser.role === Role.SuperAdmin ||
      this.currentUser.role === Role.CompanyAdmin ||
      this.currentUser.role === Role.ProjectAdmin
    );
  }

  get isSuperAdmin() {
    return this.currentUser.role === Role.SuperAdmin;
  }

  get usersLink() {
    return { name: 'Users' };
  }

  get activeUsersLink() {
    return { name: 'Users', query: { tab: 1 } };
  }

  get bookingsLink() {
    return { name: 'Bookings' };
  }

  get assetsLink() {
    return { name: 'Assets' };
  }

  get filterStartForStatus() {
    if (this.filterToday) {
      return moment()
        .startOf('day')
        .toISOString();
    }
    return this.filterStart;
  }

  get filterStopForStatus() {
    if (this.filterToday) {
      return moment()
        .endOf('day')
        .toISOString();
    }
    return this.filterStop;
  }

  get filterStart() {
    return this.dateRange.start;
  }

  get filterStop() {
    return this.dateRange.stop;
  }

  get selectedPaymentStatesForApi() {
    const states = [];
    if (this.selectedPaymentStates.includes('Open')) {
      states.push('notCreated', 'open');
    }

    if (this.selectedPaymentStates.includes('Paid')) {
      states.push('paid');
    }

    if (this.selectedPaymentStates.includes('Pending')) {
      states.push('pending', 'authorized');
    }

    if (this.selectedPaymentStates.includes('Failed')) {
      states.push('expired', 'failed', 'canceled');
    }
    return states;
  }

  get allFilters() {
    return [
      ...this.selectedCompanies,
      ...this.selectedProjects,
      ...this.selectedAssets,
    ];
  }

  get selectedCompanyIds() {
    return this.selectedCompanies.map(c => c._id);
  }

  @Watch('selectedWindow')
  onWindowChanged() {
    this.doFetchBookingStatistics();
    this.doFetchPaymentStatistics();
  }

  @Watch('selectedBookingStates')
  onBookingStatesChanged() {
    this.doFetchBookingStatistics();
  }

  @Watch('selectedPaymentStates')
  onPaymentStatesChanged() {
    this.doFetchPaymentStatistics();
  }

  applyFilters() {
    this.selectedCompanies = [...this.tempSelectedCompanies];
    this.selectedProjects = [...this.tempSelectedProjects];
    this.selectedAssets = [...this.tempSelectedAssets];
    this.onTimeframeChanged();
  }

  removeFilter(id: string) {
    const company = this.selectedCompanies.findIndex(c => c._id === id);
    if (company > -1) {
      this.selectedCompanies.splice(company, 1);
    }
    const project = this.selectedProjects.findIndex(p => p._id === id);
    if (project > -1) {
      this.selectedProjects.splice(project, 1);
    }
    const asset = this.selectedAssets.findIndex(a => a._id === id);
    if (asset > -1) {
      this.selectedAssets.splice(asset, 1);
    }
    this.onTimeframeChanged();
  }

  async doFetchProjects() {
    const projectsFilter = new Filter();
    projectsFilter.removeForKey('company');
    if (this.selectedCompanies.length > 0) {
      projectsFilter.addFilter('company', this.selectedCompanyIds);
    }
    await this.fetchProjects(projectsFilter);
    this.selectedProjects = this.projects;
  }

  @Watch('dateRange', { deep: true })
  @Watch('selectedTimeframe')
  onTimeframeChanged() {
    this.doFetchBookingStatistics();
    this.doFetchPaymentStatistics();
    this.doFetchPaymentStatusStatistics();
    this.doFetchBookingStatusStatistics();
    this.getStatData();
  }

  @Watch('filterToday')
  onFilterTodayChanged() {
    this.doFetchBookingStatusStatistics();
    this.doFetchPaymentStatusStatistics();
  }

  onFiltersClicked() {
    this.tempSelectedCompanies = [...this.selectedCompanies];
    this.tempSelectedProjects = [...this.selectedProjects];
    this.tempSelectedAssets = [...this.selectedAssets];
    this.showFilters = true;
  }

  async getUserStats() {
    const activeUserFilter = new Filter();
    activeUserFilter.addFilter('state', UserState.Active);
    if (this.selectedProjects.length > 0) {
      activeUserFilter.replaceFilter(
        'projects',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    await this.fetchUsers(activeUserFilter);
    this.numberOfActiveUsers = this.userPagination.totalDocs;

    const userFilter = new Filter();
    userFilter.addFilter('role', Role.User);
    if (this.selectedProjects.length > 0) {
      userFilter.addFilter(
        'projects',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    await this.fetchUsers(userFilter);
    this.numberOfUsers = this.userPagination.totalDocs;
    this.removeLoader('users');
  }

  async getAssetStats() {
    const assetFilter = new Filter();
    if (this.selectedProjects.length > 0) {
      assetFilter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    await this.fetchAssets(assetFilter);
    this.numberOfAssets = this.assetPagination.totalDocs;
    this.removeLoader('assets');
  }

  async getBookingStats() {
    const bookingFilter = new Filter();
    if (this.selectedAssets.length > 0) {
      bookingFilter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedProjects.length > 0) {
      bookingFilter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.filterStart) {
      bookingFilter.addFilter(
        'createdAt',
        this.filterStart,
        Operator.GreaterThanOrEqual,
      );
    }
    if (this.filterStop) {
      bookingFilter.addFilter(
        'createdAt',
        this.filterStop,
        Operator.LessThanOrEqual,
      );
    }
    await this.fetchBookings(bookingFilter);
    this.numberOfBookings = this.bookingPagination.totalDocs;
    this.removeLoader('bookings');
  }

  async getStatData() {
    this.addLoader('users');
    this.addLoader('assets');
    this.addLoader('bookings');
    this.addLoader('bookings-month');

    const projectsFilter = new Filter();
    projectsFilter.removeForKey('company');
    if (this.selectedCompanies.length > 0) {
      projectsFilter.addFilter('company', this.selectedCompanyIds);
    }
    await this.fetchProjects(projectsFilter);
    this.numberOfProjects = this.projectPagination.totalDocs;
    await this.getUserStats();
    await this.getAssetStats();

    const filter = new Filter();
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedAssets.length > 0) {
      filter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }

    this.numberOfBookingsCurrentMonth = await bookingsService.countMonth(
      filter,
    );
    this.removeLoader('bookings-month');

    await this.getBookingStats();

    if (
      this.numberOfUsers < 1 ||
      this.numberOfAssets < 1 ||
      this.numberOfProjects < 1
    ) {
      this.introCompleted = false;
      this.checklistItems[0].checked = this.numberOfProjects > 0;
      this.checklistItems[1].checked = this.numberOfAssets > 0;
      this.checklistItems[2].checked = this.numberOfUsers > 0;
    }
  }

  async doFetchBookingStatistics() {
    this.addLoader('booking-statistics');
    const filter = new Filter();
    filter.addFilter(
      'updatedAt',
      this.filterStart,
      Operator.GreaterThanOrEqual,
    );
    filter.addFilter('updatedAt', this.filterStop, Operator.LessThanOrEqual);
    filter.addFilter('window', this.selectedWindow);
    filter.addFilter('state', this.selectedBookingStates);
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedAssets.length > 0) {
      filter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }

    await this.fetchBookingStatistics(filter);
    this.removeLoader('booking-statistics');
  }

  async doFetchBookingStatusStatistics() {
    this.addLoader('booking-status-statistics');
    const filter = new Filter();
    filter.addFilter(
      'start',
      this.filterStartForStatus,
      Operator.GreaterThanOrEqual,
    );
    filter.addFilter(
      'start',
      this.filterStopForStatus,
      Operator.LessThanOrEqual,
    );
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedAssets.length > 0) {
      filter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }

    await this.fetchBookingStatusStatistics(filter);
    this.removeLoader('booking-status-statistics');
  }

  async doFetchPaymentStatistics() {
    this.addLoader('payment-statistics');
    const filter = new Filter();
    filter.addFilter(
      'createdAt',
      this.filterStart,
      Operator.GreaterThanOrEqual,
    );

    filter.addFilter('createdAt', this.filterStop, Operator.LessThanOrEqual);
    filter.addFilter('window', this.selectedWindow);
    filter.addFilter('status', this.selectedPaymentStatesForApi);
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedAssets.length > 0) {
      filter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    await this.fetchPaymentStatistics(filter);
    this.removeLoader('payment-statistics');
  }

  async doFetchPaymentStatusStatistics() {
    this.addLoader('payment-status-statistics');
    const filter = new Filter();
    filter.addFilter(
      'createdAt',
      this.filterStartForStatus,
      Operator.GreaterThanOrEqual,
    );
    filter.addFilter(
      'createdAt',
      this.filterStopForStatus,
      Operator.LessThanOrEqual,
    );
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    if (this.selectedAssets.length > 0) {
      filter.addFilter(
        'asset',
        this.selectedAssets.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }
    await this.fetchPaymentStatusStatistics(filter);
    this.removeLoader('payment-status-statistics');
  }

  created() {
    this.getStatData();
    this.fetchCompanies();
    this.doFetchBookingStatistics();
    this.doFetchBookingStatusStatistics();
    this.doFetchPaymentStatistics();
    this.doFetchPaymentStatusStatistics();
  }
}












import Vue from 'vue';
import BaseSelect from '../components/BaseSelect.vue';
import { Asset } from '@/api/assets/asset.model';
import AssetsService from '@/api/assets/assets.service';
import { CastType, Filter } from '@/shared/types/filter.class';
import Component from 'vue-class-component';
import { VModel, Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const assetsService = new AssetsService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class AssetSelect extends Vue {
  @VModel({ type: Array })
  data!: Asset[];

  @Prop()
  multiple!: boolean;

  @Prop({
    type: Object,
    default: () => new Filter(),
  })
  filter!: Filter;

  @Prop()
  projects!: string[];

  localFilter: Filter = new Filter();

  @Watch('projects', { immediate: true, deep: true })
  onProjectsChanged() {
    this.localFilter.removeForKey('project');
    if (this.projects.length > 0) {
      this.localFilter.replaceFilter(
        'project',
        this.projects,
        undefined,
        CastType.ObjectId,
      );
    }
  }

  @Watch('filter', { immediate: true, deep: true })
  onFilterChanged() {
    this.localFilter = this.filter.copy();
  }

  async fetchAssets(filter: Filter): Promise<PaginateResult<Asset>> {
    return assetsService.fetch(filter);
  }
}

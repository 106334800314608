<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    values: {
      type: Array,
    },
    prependLabel: String,
  },
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
        showTooltips: true,

        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                drawBorder: false,
              },
              ticks: {
                callback: this.yLabelCallback,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    data: function() {
      return {
        labels: this.values.map(x => x._id),
        datasets: [
          {
            label: '',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#4936C2',
            data: this.values.map(x => x.value.toFixed(2)),
          },
        ],
      };
    },
  },
  methods: {
    yLabelCallback(value, index, values) {
      if (this.prependLabel) {
        return `${this.prependLabel} ${value}`;
      }
      return value;
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
































import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class Statcard extends Vue {
  @Prop()
  icon!: string;

  @Prop()
  link!: string;

  @Prop()
  label!: string;

  @Prop()
  value!: string;

  @Prop()
  badge!: string;

  @Prop()
  badgeType!: string;

  @Prop({ default: false })
  loading!: boolean;

  onClick() {
    console.log('clicked', this.link);
  }
}

<script>
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    values: {
      type: Array,
    },
    identifier: {
      type: String,
    },
  },
  computed: {
    data: function() {
      return {
        labels: this.values.map(x => x._id),
        datasets: [
          {
            label: '',
            backgroundColor: [
              '#4936C2',
              '#2C7BE5',
              '#0018D1',
              '#564DFA',
              '#3119BD',
              '#563CE8',
            ],
            data: this.values.map(x => x.value),
          },
        ],
      };
    },
  },
  mounted() {
    this.renderChart(this.data, {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 83,
      legend: {
        position: 'bottom',
        display: true,
      },
      labels: {
        usePointStyle: true,
      },
    });
  },
};
</script>



























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import { User } from '../api/users/user.class';
import { Filter } from '../shared/types/filter.class';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { Payment } from '../api/payments/payment.model';
import { PaymentStatus } from '../api/payments/payment-status.enum';
import { UserPaymentStatus } from '../api/users/user-payment-status.enum';

const usersModule = namespace('users');
const bookingsModule = namespace('bookings');
const paymentsModule = namespace('payments');

// The @Component decorator indicates the class is a Vue component
@Component({ components: {} })
export default class HomeUser extends Vue {
  showAlert = false;
  alertText = '';
  alertVariant = 'info';
  alertButtonText = null;
  alertButtonLink = null;

  filter = new Filter();

  @usersModule.Getter('loggedInUser')
  currentUser!: User;

  @paymentsModule.Getter('pagination')
  paginatedPayments!: PaginateResult<Payment>;

  @paymentsModule.Action('fetchAll')
  fetchAllPayments!: (filter?: Filter) => Promise<PaginateResult<Payment>>;

  created() {
    if (this.currentUser.paymentStatus === UserPaymentStatus.new) {
      this.showAlert = false;
      this.alertVariant = 'info';
      this.alertText = 'Open the app to get your first paid booking experience';
    } else if (this.currentUser.paymentStatus === UserPaymentStatus.paying) {
      this.showAlert = true;
      this.alertVariant = 'success';
      this.alertText = 'Payments are being processed automatically';
    } else if (
      this.currentUser.paymentStatus === UserPaymentStatus.firstPaymentPending
    ) {
      this.showAlert = true;
      this.alertVariant = 'danger';
      this.alertText =
        'We are waiting until your first payment has been completed before you can book again';
      this.alertButtonText = 'Go to payments';
      this.alertButtonLink = '/payments?tab=1';
    } else if (
      this.currentUser.paymentStatus === UserPaymentStatus.renewalRequired
    ) {
      this.showAlert = true;
      this.alertVariant = 'danger';
      this.alertButtonText = 'Go to payments';
      this.alertButtonLink = '/payments?tab=1';
      this.alertText =
        'Your payment mandate has expired and we need to re-activate it! You can do this by completing a payment';
    }
  }
}
